import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

// Components
import { BannerAlt, TitleDefault } from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import ParseContent from 'components/ParseContent'
import RequestOffer from 'components/RequestOffer'

import 'styles/templates/BlogTemplate.scss'

export const postquery = graphql`
  query($wordpress_id: Int) {
    post: wordpressPost(wordpress_id: { eq: $wordpress_id }) {
      path
      title
      content
      acf {
        image: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 1024, toFormat: JPG) {
                src
              }
            }
          }
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const BlogPost = ({
  data: {
    post: {
      path,
      title,
      content,
      acf: { image },
      yoastMeta,
    },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={image.localFile.childImageSharp.fixed.src}
        article
      />
      <div className="blog-template">
        <BannerAlt>
          <BackgroundImage
            className="banner-background-image"
            fluid={image.localFile.childImageSharp.fluid}
          />
          <div className="banner-content row pt-5">
            <div className="col-lg-6 pt-5 text-center text-lg-left">
              <h1 className="mt-5">
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </h1>
            </div>
            <div className="col-lg-6" />
          </div>
        </BannerAlt>

        <div className="blog container">
          <div className="row py-4">
            <div className="col-lg-10 mx-lg-auto">
              <div className="page-container container font-family-secondary text-justify mt-5 font-size-sm font-family-secondary">
                <TitleDefault>{title}</TitleDefault>
                <ParseContent content={content} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <RequestOffer isRed />
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost
